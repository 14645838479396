<template>
  <v-app id="inspire">

    <v-app-bar
        app
        color="primary"
    >
      <v-app-bar-nav-icon class="white--text" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-avatar
          class="ml-1"
          size="48"
          @click="$router.push('/')"
      >
        <img
            src="LOGO_SCB_web.png"
            alt="SCB"
        >
      </v-avatar>

      <v-toolbar-title
          class="ml-2 white--text"
          @click="$router.push('/')"
      >
        {{$router.currentRoute.name}}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-responsive max-width="260">
        <v-text-field
            class="green"
            dense
            flat
            hide-details
            rounded
            solo-inverted
            append-icon="mdi-magnify"
        ></v-text-field>
      </v-responsive>

    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        app
    >
      <v-card
        bor
      >
        <v-img
            class="white--text align-end"
            max-height="125px"
            src="Header3.jpg"
            gradient="to top right, rgba(66,66,66,.99), rgba(66,66,66,.20)"
        >
          <v-card-title
              class="white--text"
          >
            Judo in Garbsen
          </v-card-title>
          <v-card-subtitle>
            SC Budokwai Garbsen e.V.
          </v-card-subtitle>
        </v-img>


      </v-card>
      <v-divider></v-divider>
      <v-tabs
          vertical
      >
        <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :to="tab.to"

        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
    </v-navigation-drawer>
    <v-img
        src="logo.jpg"
        max-height="100vh"
        contain
        gradient="to top right, rgba(254,254,254,.95), rgba(254,254,254,.95)"
        class="overflow-auto"
    >
      <v-main

      >
        <v-fab-transition>
          <router-view/>
        </v-fab-transition>
      </v-main>
    </v-img>

  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    tabs: [
      {title: "Home", to: "/", disabled: false},
      {title: "Trainingsplan", to: "/trainingsplan", disabled: false},
      {title: "Vorstand", to: "/vorstand", disabled: true},
      {title: "Beiträge & Gebühren", to: "/gebuhren", disabled: true},
      {title: "Formulare & Downloads", to: "/downloads", disabled: true},
      {title: "Impressum", to: "/impressum", disabled: true}

      //{title: "Kalender", to: "/kalender"},
    ]
  }),
}
</script>
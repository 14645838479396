import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#008000',
                secondary: '#424242',
                background: '#FFFFFF'
            },
            dark: {
                primary: '#7b0000',
                secondary: '#424242'
            },
        },
    },
})

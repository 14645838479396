<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
          v-for="(vorstand, i) in vorstands"
          :key="i"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="4"
          xl="4"
      >
        <v-card
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title
                  class="text-h5"
                  v-text="vorstand.funktion"
              ></v-card-title>

              <v-card-subtitle v-text="vorstand.name"></v-card-subtitle>

              <v-card-actions>
                <v-btn
                    class="primary--text ml-2 mt-5"
                    outlined
                    rounded
                    small
                >
                  E-Mail
                </v-btn>
                <v-btn
                    class="primary--text ml-2 mt-5"
                    outlined
                    rounded
                    small
                >
                  Anrufen
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar
                class="ma-3"
                size="125"
                tile
            >
              <v-img
                  :src="vorstand.src"
              ></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Vorstand",
  data: () => ({
    vorstands: [
      {
        color: "primary",
        funktion: "1. Vorsitzender",
        name: "Leonard Birkenfeld",
        src: "https://previews.123rf.com/images/apoev/apoev1904/apoev190400006/124108707-person-graue-fotoplatzhalter-mann-silhouette-auf-wei%C3%9Fem-hintergrund.jpg",
        mail: "",
        tel: ""
      },
      {
        color: "primary",
        funktion: "2. Vorsitzender",
        name: "Falk Oberwetter",
        src: "https://previews.123rf.com/images/apoev/apoev1904/apoev190400006/124108707-person-graue-fotoplatzhalter-mann-silhouette-auf-wei%C3%9Fem-hintergrund.jpg",
        mail: "",
        tel: ""
      },
      {
        color: "primary",
        funktion: "Kassenwarting",
        name: "Christin Hirche",
        src: "https://previews.123rf.com/images/apoev/apoev1904/apoev190400006/124108707-person-graue-fotoplatzhalter-mann-silhouette-auf-wei%C3%9Fem-hintergrund.jpg",
        mail: "",
        tel: ""
      },
      {
        color: "primary",
        funktion: "Schriftführer",
        name: "Narek Shahverdyan",
        src: "https://previews.123rf.com/images/apoev/apoev1904/apoev190400006/124108707-person-graue-fotoplatzhalter-mann-silhouette-auf-wei%C3%9Fem-hintergrund.jpg",
        mail: "",
        tel: ""
      },
      {
        color: "primary",
        funktion: "Gerätewart",
        name: "Tim Baake",
        src: "https://previews.123rf.com/images/apoev/apoev1904/apoev190400006/124108707-person-graue-fotoplatzhalter-mann-silhouette-auf-wei%C3%9Fem-hintergrund.jpg",
        mail: "",
        tel: ""
      },
      {
        color: "primary",
        funktion: "Jugendwart",
        name: "Reckhaus",
        src: "https://scontent-ber1-1.xx.fbcdn.net/v/t31.18172-1/1556369_237698573068985_1119826023_o.jpg?stp=c40.0.160.160a_dst-jpg_p160x160&_nc_cat=103&ccb=1-7&_nc_sid=7206a8&_nc_eui2=AeG-CFWfn3S8m03euVPRk7kHlXeVWDs9JZKVd5VYOz0lkm0ANCLTclhmlG9MffivsxZpAeuXWdKsagCyPIiiGiHV&_nc_ohc=PNPYkE1KThQAX8e0eHx&_nc_ht=scontent-ber1-1.xx&oh=00_AfAjQaBpDC0uO_bbJtFA3fRmQiZcObf_DDfRJL2SWD-Zvw&oe=644F969A",
        mail: "",
        tel: ""
      },
      {
        color: "primary",
        funktion: "Sportwart",
        name: "Vahe Shahverdyan",
        src: "https://previews.123rf.com/images/apoev/apoev1904/apoev190400006/124108707-person-graue-fotoplatzhalter-mann-silhouette-auf-wei%C3%9Fem-hintergrund.jpg",
        mail: "",
        tel: ""
      },
      {
        color: "primary",
        funktion: "Pressereferent",
        name: "Marc Reinelt",
        src: "https://previews.123rf.com/images/apoev/apoev1904/apoev190400006/124108707-person-graue-fotoplatzhalter-mann-silhouette-auf-wei%C3%9Fem-hintergrund.jpg",
        mail: "",
        tel: ""
      },
    ],
    items: [
      {
        color: '#1F7087',
        src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
        title: 'Supermodel',
        artist: 'Foster the People',
      },
      {
        color: '#952175',
        src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
        title: 'Halcyon Days',
        artist: 'Ellie Goulding',
      },
    ],
  }),
}
</script>

<style scoped>

</style>
<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-title>
            Trainingsplan
          </v-card-title>
          <v-card-subtitle>
            Schaut einfach vorbei. Bitte vergesst nicht Sportbekleidung und genügend zu Trinken mitzubringen. Judo machen wir Barfuß.
          </v-card-subtitle>
          <v-card-text>
            <v-data-table
                :headers="trainingsplan_headers"
                :items="traningsplan"
                :items-per-page="5"
                class="elevation-1"
                disable-sort
                hide-default-footer

            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
<!--
      <v-col
        cols="12"
      >
        <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            @change="getEvents"
        ></v-calendar>
      </v-col>

      <v-col
        cols="12"
      >
        blabla text unter der Tabelle
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Trainingsplan",
  data() {
    return {
      trainingsplan_headers: [
        {'value': "wochentag", 'text': "Wochentag"},
        {'value': "uhrzeit", 'text': "Uhrzeit"},
        {'value': "zielgruppe", 'text': "Zielgruppe"},
        {'value': "ort", 'text': "Ort"},
        {'value': "trainer", 'text': "Trainer"}
      ],
      traningsplan: [
        {'wochentag':  "Dienstag", "uhrzeit": "19:00 - 20:30", "zielgruppe": "Erwachsene und Jugendliche", "ort": "IGS / Halle A", "trainer": "Alexander Bannert"},
        {'wochentag':  "Mittwoch", "uhrzeit": "18:00 - 19:30", "zielgruppe": "Erwachsene und Jugendliche", "ort": "IGS / Halle A", "trainer": "Alexander Bannert"},
        {'wochentag':  "Donnerstag", "uhrzeit": "16:30 - 18:00", "zielgruppe": "Kinder", "ort": "Turnhalle Stelingen", "trainer": "Arne Reckhaus & Leon Stürzebecher"},
        {'wochentag':  "Freitag", "uhrzeit": "16:00 - 18:00", "zielgruppe": "Erwachsene und Jugendliche", "ort": "IGS / Halle A", "trainer": "Leonard Birkenfeld"}
      ],

      type: 'week',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: 'Sontag - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

    }
  },
  methods: {
    getEvents ({ start, end }) {
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        })
      }

      this.events = events
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>

<style scoped>

</style>
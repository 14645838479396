<template>
  <v-container
      fluid
  >
    <v-row>
      <v-col
      >
        <v-card>
          <v-card-title>
            Mitgliedbeiträge
          </v-card-title>
          <v-card-subtitle>
            Die Monatsbeiträge werden quartalsweise per Lastschrift jeweils zu Beginn des zweiten Monats des jeweiligen Quartals eingezogen.
            Kündigungen sind jeweils einen Monat zum Quartalsende möglich.
          </v-card-subtitle>
          <v-card-text>
            <v-data-table
                :headers="beitrag_header"
                :items="beitrag"
                :items-per-page="5"
                class="elevation-1"
                disable-sort
                hide-default-footer

            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
      >
        <v-card>
          <v-card-title>
            Gebühren
          </v-card-title>
          <v-card-subtitle>
            Der Jahresbeitrag des Niedersächsischen Judo Verbandes (NJV) und des Deutschen Judo Bundes (DJB) wird im ersten Quartal des Jahres zusammen mit den Monatsbeiträgen abgebucht. Als Nachweis (Voraussetzung zur Teilnahme an Prüfungen und Wettkämpfen) bekommt jedes Mitglied Anfang des Jahres eine Jahressichtmarke.



          </v-card-subtitle>
          <v-card-text>
            <v-data-table
                :headers="gebuhren_header"
                :items="gebuhren"
                :items-per-page="5"
                class="elevation-1"
                disable-sort
                hide-default-footer

            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BeitrageUGebuhren",
  data(){
    return {
      beitrag_header: [
        {'value': "abo", 'text': ""},
        {'value': "aufnahmegebuhr", 'text': "Aufnahmegebühr"},
        {'value': "monatsbeitrag", 'text': "Monatsbeitrag"}
      ],
      beitrag: [
        {"abo": "Behinderte", "aufnahmegebuhr": "10€", "monatsbeitrag": "6,50€"},
        {"abo": "Jugendliche", "aufnahmegebuhr": "20€", "monatsbeitrag": "6,50€"},
        {"abo": "Erwachsene", "aufnahmegebuhr": "30€", "monatsbeitrag": "10,50€"},
        {"abo": "Familien", "aufnahmegebuhr": "60€", "monatsbeitrag": "21€"},
      ],

      gebuhren_header: [
        {'value': "produkt", 'text': ""},
        {'value': "bemerkung", 'text': "Bemerkung"},
        {'value': "falligkeit", 'text': "Fälligkeit"},
        {'value': "betrag", 'text': "Betrag"}
      ],
      gebuhren: [
        {"produkt": "Judo-Lernpass", "bemerkung": "Kinder (5-7 Lebensjahre)", "falligkeit": "einmalig (bei Aufnahme)", "betrag": "13€"},
        {"produkt": "Judopass", "bemerkung": "ohne Jahressichtmarke", "falligkeit": "einmalig (bei Aufnahme)", "betrag": "10€"},
        {"produkt": "Jahressichtmarke", "bemerkung": "", "falligkeit": "jährlich (im Februar oder bei Aufnahme)", "betrag": "24€"},
        {"produkt": "Prüfungsgebühren inkl. Urkunde", "bemerkung": "", "falligkeit": "Bei Prüfung", "betrag": "10€"}
      ],
    }
  }
}
</script>

<style scoped>

</style>
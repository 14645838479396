<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
          cols="12"
          xs="12"
          sm="12"
          md="4"
          lg="4"
          xl="3"
      >
        <v-card>
          <v-card-title>
            Eintrittsformular
          </v-card-title>
          <v-card-subtitle>
            Einfach ausfüllen und zum nächsten Training mitbringen.
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="primary--text"
                outlined
                rounded
                text
                href="Satzung.pdf"
            >
              Download
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
          cols="12"
          xs="12"
          sm="12"
          md="4"
          lg="4"
          xl="3"
      >
        <v-card
        >
          <v-card-title>
            Vereinssatzung
          </v-card-title>
          <v-card-subtitle>
            Satzung des SC Budokwai Garbsen e.V.
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="primary--text"
                outlined
                rounded
                text
                href="Satzung.pdf"
            >
              Download
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
          cols="12"
          xs="12"
          sm="12"
          md="4"
          lg="4"
          xl="3"
      >
        <v-card>
          <v-card-title>
            Kyu Prüfungsordnung
          </v-card-title>
          <v-card-subtitle>
            Zur Vorbereitung auf deinen nächsten Gürtel findest du hier die Prüfungsordnung.
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="primary--text"
                outlined
                rounded
                text
                href="Satzung.pdf"
            >
              Download
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FormulareUDownloads"
}
</script>

<style scoped>

</style>
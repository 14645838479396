<template>
  <v-container
      fluid
  >
    <v-row>
      <v-spacer></v-spacer>
      <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="10"
          xl="10"
      >
        <v-carousel
            v-model="model"
            height="50vh"
            cycle
        >
          <v-carousel-item
              v-for="(item, index) in items"
              :key="index"
              :src="item.src"
              transition="fade-transition"
          >
            <v-overlay value="true" absolute>
              <v-row
                  class="fill-height white--text"
                  align="center"
                  justify="center"
              >
                <div class="text-h2">
                  {{ item.text }}
                  <v-spacer></v-spacer>
                  <v-btn
                      x-large
                      class="primary white--text"
                      outlined
                      :to="item.link"
                  >
                    Hier klicken
                  </v-btn>
                </div>
              </v-row>
            </v-overlay>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Home",
  components: {

  },
  data: () => ({
    model: 0,
    items: [
      {"src": "Neuigkeiten1.jpg", "text": "Neuer Traingsplan", "link": "/trainingsplan"},
      {"src": "Header1.jpg", "text": "14. Jahreshauptversammlung", "link": "/presse"}
    ],
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red',
      'orange',
    ],
  }),
};
</script>

import { render, staticRenderFns } from "./Vorstand.vue?vue&type=template&id=cfbdbc36&scoped=true&"
import script from "./Vorstand.vue?vue&type=script&lang=js&"
export * from "./Vorstand.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfbdbc36",
  null
  
)

export default component.exports
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Trainingsplan from "../views/Trainingsplan";
import Vorstand from "../views/Vorstand";
import BeitrageUGebuhren from "../views/BeitrageUGebuhren";
import FormulareUDownloads from "../views/FormulareUDownloads";
import Impressum from "../views/Impressum";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/trainingsplan",
    name: "Trainingsplan",
    component: Trainingsplan,
  },
  {
    path: "/vorstand",
    name: "Vorstand",
    component: Vorstand,
  },
  {
    path: "/gebuhren",
    name: "Beiträge & Gebühren",
    component: BeitrageUGebuhren,
  },
  {
    path: "/downloads",
    name: "Formulare & Downloads",
    component: FormulareUDownloads,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
